<template>
    <div class="radio-group">
      <label class="radio-option" :class="{ active: paymentOption === 'existing' }">
        <input type="radio" value="existing" v-model="paymentOption" />
        <span class="custom-radio"></span>
        Use Existing Card
      </label>
  
      <label class="radio-option" :class="{ active: paymentOption === 'update' }">
        <input type="radio" value="update" v-model="paymentOption" />
        <span class="custom-radio"></span>
        <span>
            Update Credit Card
        </span>
      </label>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        onChangeValue: Function,
    },
    data() {
      return {
        paymentOption: "existing",
      };
    },
    methods: {
      
    },
    watch: {
        paymentOption: {
            handler(newValue){
                this.onChangeValue(newValue)
            }
        }
    }
  };
  </script>
  
  <style scoped>
  /* Container radio group */
  .radio-group {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
  }
  
  /* Custom radio option */
  .radio-option {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    position: relative;
    opacity: 0.5;
  }
  
  /* Efek aktif */
  .radio-option.active {
    border-color: var(--text-primary-color);
    background-color: transparent;
    opacity: 1;
  }
  
  /* Sembunyikan radio default */
  .radio-option input {
    display: none;
  }
  
  /* Custom radio button */
  .custom-radio {
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    position: relative;
  }
  
  /* Efek jika dipilih */
  .radio-option input:checked + .custom-radio {
    border-color: var(--em-primary);
    background-color: var(--em-primary);
  }
  
  /* Efek bulatan dalam saat dipilih */
  .radio-option input:checked + .custom-radio::after {
    content: "";
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Container untuk input kartu baru */
  .input-container {
    margin-top: 10px;
  }
  </style>
  