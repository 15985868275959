<template>
        <!-- START THE CARD CONTENT -->
        <ValidationObserver ref="formcc">
          <form @submit.prevent="validate" id="updatecc" class="row" style="margin-top: 16px;">
          <small style="margin-left: 13px;">Card Number</small>
          <div id="card-element" class="col-sm-12 col-md-12 col-lg-12 card-border">&nbsp;</div> <div class="pt-2 pl-2" v-if="cardfailetoload" style="font-size:0.80143rem"><i class="fas fa-sync-alt pr-2" @click="refreshCCfield();" style="cursor:pointer"></i>Failed to load the Credit Card field. Please <a href="javascript:void(0);" @click="refreshCCfield();" style="font-weight:bold">click here</a> to refresh and try again.</div>
          <div id="carderror" class="col-sm-12 col-md-12 col-lg-12 pt-2 hide" style="color:red"><small>&nbsp;</small></div>
            
          <div class="col-sm-12 col-md-12 col-lg-12">
            <ValidationProvider
              name="Card Holder Name"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
            <base-input
              v-model="cardholdername"
              label="Card Holder Name"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12">
            <ValidationProvider
              name="Billing Address"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
            <base-input
              v-model="billingaddress"
              label="Billing Address"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
            <div class="country-region-select-wrapper">
              <span  class="country-label">Country *</span>
              <country-select  class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
              <span v-if="showErrorMessage && !selects.country" style="color: #942434;">Please select country</span>
            </div> 
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
            <div v-if="selects.country" class="country-region-select-wrapper">
            <span class="country-label">{{selects.country === "CA" ?"Province *": "State *"}}</span>
            <region-select  class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state"  />
            <span v-if="showErrorMessage && !selects.state" style="color: #942434;">Please select state</span>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6">
              <ValidationProvider
                name="City"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
                <base-input
                  v-model="city"
                  label="City"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6">
            <ValidationProvider
                name="Zip"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
              <base-input
                  v-model="zipcode"
                  label="Zip"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12" style="padding-top: 16px;">
              <card>
                  <div slot="header"><h4 class="card-title">Your Current Card Information</h4></div>
              </card>
          </div>
        
          <div class="col-sm-12 col-md-12 col-lg-12 mr-0 ml-0">
              <p v-if="currCardHolder != ''">{{currCardHolder}}</p>
              <p v-if="currCardlastdigit != ''">Card Number : &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull; {{currCardlastdigit}}</p>
              <p v-if="currCardType != ''">Card Type : {{currCardType}}</p>
          </div>
          
          <div class="col-sm-12 col-md-12 col-lg-12 ">
              <base-checkbox :disabled="agreeTermDisable" v-model="agreeTerm" class="pull-left" :class="{'has-danger': agreeTermStat}">I have read and agree to the <a href="#termofbilling" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[agreeTermStat?{'color':'#ec250d'}:'']" v-on:click.stop.prevent="modals.termofbilling = true"> service and billing agreement</a></base-checkbox> 
          </div>
          <div class="pt-3 pb-3">&nbsp;</div>
          <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
              <!-- <base-button id="btnupdatecc" @click="validate('')" size="sm" class="pull-right" style="height:40px">
                  Update Card
              </base-button> -->
          </div>

          </form>
        <!-- Card Update Modal -->
         <modal :show.sync="modals.cardupdate" headerClasses="justify-content-center">
           <h4 slot="header" class="title title-up">Card Information</h4>
           <p>
             Thank you! Your Credit Card Information Has been updated.
           </p>
           <template slot="footer">
             <div class="container text-center pb-4">
               <base-button  @click.native="modals.cardupdate = false">Ok</base-button>
             </div>
           </template>
         </modal>
        <!-- Card Update Modal -->
 
        <!-- MODAL TERM OF BILLING-->
         <modal :show.sync="modals.termofbilling" headerClasses="justify-content-center" id="modaltermofbilling">
           <div>
             <iframe src="/service-billing-agreement" width="100%"  height="400vh"/>
           </div>
           <template slot="footer">
             <div class="container text-center pb-4">
               <base-button @click="agreedtermofbilling();">Yes, I Understand</base-button>
             </div>
             <div class="container text-center pb-4">
               <a href="/service-billing-agreement/" target="_blank">Click here for service and billing agreement page</a>  
             </div>
           </template>
         </modal>
       <!--MODAL TERM OF BILLING -->
        </ValidationObserver>
        <!-- START THE CARD CONTENT -->
       
        
</template>

<script>
import { Select, Option } from 'element-ui';
import { Modal,BaseCheckbox } from '/src/components';
import swal from 'sweetalert2';


var _elements;
var _cardElement;
var _stripe;
var _tokenid = '';
var _sppubkey = '';
var _this;

export default {
    components: {
        Modal,
        BaseCheckbox,
        [Option.name]: Option,
        [Select.name]: Select,
    },
    data() {
        return {
            currCardHolder: '',
            showErrorMessage: false,
            cardfailetoload:false,
            currCardlastdigit: '',
            currCardType: '',

            cardholdername: '',
            billingaddress: '',
            city: '',
            zipcode: '',
            selects: {
                state: '',
                country: '',
                statelist: [],
            },
            agreeTermDisable:false,
            agreeTermBilling:false,
            agreeTermStatBilling:false,
            agreeTerm:false,
            agreeTermStat:false,

            modals: {
                cardupdate: false,
                termofbilling: false,
                cardretrycharge: false,
            },

            cardretrychargeTitle: '',
            cardretrychargeTxt: '',

            clientPaymentFailed: false,
            agencyPaymentFailed: false,
            failedCampaignNumber: [],
            failedInvoiceAmount: [],
            failedTotalOutstanding:0,
        };
    },

    methods: {
      refreshCCfield() {
        if (this.$global.sppubkey != "") {
          _sppubkey = this.$global.sppubkey;
          this.initstripelib();
          //this.cardfailetoload = false;
        }else{
          this.$store.dispatch('getGeneralSetting', {
                companyID: this.user.company_root_id,
                settingname: 'rootstripe',
            }).then(response => {
              _sppubkey = response.data;
              this.initstripelib();
              //this.cardfailetoload = false;
            },error => {
              this.$notify({
                type: 'primary',
                message: 'Please try to refresh the page',
                icon: 'fas fa-bug'
              })
              this.cardfailetoload = true;    

            });
        }
      },
        agreedtermofbilling() {
          this.agreeTerm = true;
          this.agreeTermDisable = false;
          this.modals.termofbilling = false;
        },
        processUpdateCard() {
            var _store = this.$store;
            var _this = this;
            const userData = this.$store.getters.userData;
            $('.btnNext2').attr('disabled', true).html('<i class="fa fa-spinner fa-spin"></i> <span style="margin-left: 8px;">Loading...</span>');

            return new Promise((resolve, reject) => {
              _stripe.createToken(_cardElement).then(function(result) {
                  if(result.error) {
                      $('#carderror').html('<small style="color:red"><sup>*</sup>' + result.error.message + '</small>');
                      $('#carderror').show();
                  }else{
                      $('#carderror').hide();
                      _tokenid = result.token.id;
                      _store.dispatch('processUpdateCard', {
                          tokenid: _tokenid,
                          cardholder: _this.cardholdername,
                          address: _this.billingaddress,
                          city: _this.city,
                          state: _this.selects.state,
                          country: _this.selects.country,
                          zip: _this.zipcode,
                          usrID: userData.id,
                          companyParentID: userData.company_parent,
                          action: '',
                      }).then(response => {
                          if(response.result == 'success' && response.msg != '') {
                            
                            _this.$store.dispatch('retrieveUserData',{
                              usrID: userData.id,
                            });
                            
                            swal.fire({
                              icon: "success",
                              title: response.title,
                              text: response.msg
                            });
                            
                            _this.getCardInfo();
                            _this.cleanform();
                            _this.$refs.formcc.reset();  
                            _this.modals.cardupdate = false;
                            // _this.modals.cardretrycharge = false;                    
                            // _this.$global.agencyPaymentFailed = false;
                            $('.btnNext2').attr('disabled', false).html('Go To General Settings');
                            resolve(true)
                          }else{
                            
                            _this.$store.dispatch('retrieveUserData',{
                              usrID: userData.id,
                            });
                            swal.fire({
                              icon: "error",
                              title: response.title,
                              text: response.msg
                            });
                            _this.getCardInfo();
                            _this.cleanform();
                            _this.$refs.formcc.reset();  
                            _this.modals.cardupdate = false;
                            // _this.cardretrychargeTitle = response.title;
                            // _this.cardretrychargeTxt = response.msg;
                            // _this.modals.cardretrycharge = true;  
                            $('.btnNext2').attr('disabled', false).html('Next');
                            resolve(false)       
                          }
                      },error => {
                        $('.btnNext2').attr('disabled', false).html('Next');
                        resolve(false)
                      });
                  }
              });
            });
        },
        cleanform() {
          this.cardholdername = '';
          this.billingaddress = '';
          this.city = '';
          this.selects.state = '';
          this.zipcode = '';
          this.agreeTerm = false;
          this.agreeTermStat = false;
          _cardElement.clear();
        },
        validate() {
          if (this.selects.country == '') {
            this.$notify({
              type: 'primary',
              message: 'Please select country',
              icon: 'fas fa-bug'
            })
            this.showErrorMessage = true;
          }

          if (this.selects.state == '') {
            this.$notify({
              type: 'primary',
              message: 'Please select state',
              icon: 'fas fa-bug'
            })
            this.showErrorMessage = true;
          } else {
            $('#updatecc #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-danger');
            $('#updatecc #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-success');
          }

          this.agreeTermStat = false;
          if (this.agreeTerm == false) {
            this.agreeTermStat = true;
          }

          return this.$refs.formcc.validate().then(res => {
            if (res) {
              this.agreeTermStat = false;
              if (this.agreeTerm == false) {
                this.agreeTermStat = true;
              
                return false;
              }

              if (this.selects.state != '' && this.selects.country != '') {
                /** UPDATE THE CARD INFORMATION */
                return this.processUpdateCard();
                /** UPDATE THE CARD INFORMATION */
              }else{
                this.showErrorMessage = true;
              }
            }
          });
        },
        initstripelib() {
            var chkload = this.$global.stripescriptload;
            if (chkload === false) {
                this.$global.initStripeScriptLoad(true);
                $.getScript( "https://js.stripe.com/v3/" )
                .done(function( script, textStatus ) {
                    initcreditcard();
                })
                .fail(function( jqxhr, settings, exception ) {
                    //$( "div.log" ).text( "Triggered ajaxError handler." );
                });
            }else{
              initcreditcard();
            }
        
        },
        getCardInfo() {
            const userData = this.$store.getters.userData;
             this.$store.dispatch('getCardInfo', {
                    usrID: userData.id,
            }).then(response => {
               //console.log(response.params);
               this.currCardHolder = (response.params.name != null)?response.params.name:''
               this.currCardlastdigit = (response.params.last4 != null)?response.params.last4:''
               this.currCardType = (response.params.brand != null)?response.params.brand:''
            },error => {
                
            });
        },

    },
    watch: {
      agreeTerm: function(value) {
        if (value == true) {
          this.agreeTermStat = false;
        }
      },
    },
    mounted() {
        _sppubkey = this.$global.sppubkey;
        _this = this;
        
        this.cardfailetoload = false;

        if (typeof(_sppubkey) == "undefined" || _sppubkey == "") {
          this.cardfailetoload = true;
        }else{
          this.initstripelib();
        }
        
        this.getCardInfo();
    }
}

function initcreditcard() {
  if (!$('#card-element').length) {
    return
  }
  let activeTheme =  localStorage.getItem("user-theme");
	_stripe = Stripe(_sppubkey);
	/** ATTACHED CARD ELEMENTS */
   _elements = _stripe.elements();
    _cardElement = _elements.create('card', {
    hidePostalCode: true,    
    style: {
        base: {
                color: activeTheme === "light-theme" ? '#101111' : '#ffffff',
                fontSize: '16px',
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: activeTheme === "light-theme" ? '#101111' : '#ffffff',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                  color: activeTheme === "light-theme" ? '#101111' : '#ffffff',
                },
            },
        },
    });

    _this.cardfailetoload = false;

    if (typeof(_cardElement) == "undefined") {
      _this.cardfailetoload = true;
    }
    //var cardElement = elements.create('card');
    _cardElement.mount('#card-element');
    /** ATTACHED CARD ELEMENTS */

    /** CARD ELEMENT VALIDATION */
    _cardElement.on('change', function(event) {
        if (event.error) {
          console.log('Card Error :' + event.error.message);
          $('#carderror').html('<small style="color:red"><sup>*</sup>' + event.error.message + '</small>');
          $('#carderror').show();
        } else {
          $('#carderror').hide();
        }
    });

	
}
</script>
<style>
/* .select-primary.el-select .el-input input {
  color: rgba(255, 255, 255, 0.8);
} */

#updatecc .card-border {
  /* width: 80% !important; */
  max-width: 96% !important;
  left: 15px;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #525f7f;
  border-radius:4px;
  padding: 8px 12px 6px 12px;
  margin:8px 0px 4px -1px;
  height:36px;
  box-sizing: border-box;
  transform: translateZ(0);
  -webkit-user-select:text;
}

#modaltermofbilling ul li {
  color: #222a42 !important;
}
</style>